import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from 'react-helmet';



class ExpenseReimbursements extends Component {

    render() {
        return (
            <>
            <Helmet>
                <title>Expense Reimbursements - Kazh.com | Spend Management & Company Card</title>
            </Helmet>
                
                <section id="banner">
                <Header />
                    <div class="container-fluid title">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

                                <div class="text" style={{ width: '60%', }}>
                                    <h1 data-aos="fade-down">Expense Reimbursements</h1>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img width="70%" src="./images/product-2.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 col-12 text-lg-left text-center">

                                <h1>

                                    Automate expense reports via mobile</h1>
                                <p>Your business teams sometimes need to pay out of pocket. Now they can use a mobile app
                                    to instantly claim reimbursement for expenses.</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Automate mileage and Travel
                                    allowance calculations</h1>
                                <p data-aos="fade-down">Manage mileage, Food and hotel
                                    allowances: the employees simply enter trip details, and Kazh calculates the exact amount of expense
                                    reimbursement required.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/product-7.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img data-aos="fade-left" width="70%" src="./images/product-5.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5col-12 text-lg-left text-center">

                                <h1 data-aos="fade-down">Stay compliant and ready for audit</h1>
                                <p data-aos="fade-down">
                                    View all travel expenses in real time. Stay compliant with built-in, up-to-date VAT calculations and receipt
                                    collection.

                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Make expense reimbursements right
                                    from Kazh.com</h1>
                                <p data-aos="fade-down">Trigger expense reimbursements right from the
                                    Kazh wallet. Reduce risks and reconcile payments faster.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12 mt-lg-0 mt-4 mt-md-4 order-lg-1 order-2">
                                <img data-aos="fade-right" width="70%" src="./images/product-2.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img data-aos="fade-left" width="70%" src="./images/virtual_card.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 col-12 text-lg-left text-center">

                                <h1 data-aos="fade-down">Consolidate and track all payments</h1>
                                <p data-aos="fade-down">
                                    Manage employee expense reimbursements along with other transactions for better cost centre tracking and
                                    budget follow-up. Receipts are digitised and stored safely.

                                </p>

                            </div>
                        </div>
                    </div>
                </section>
               
                <Footer />
            </>
        );
    }
}

export default ExpenseReimbursements;
