import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from 'react-helmet';



class VirtualCards extends Component {

    render() {
        return (
            <>
             <Helmet>
                <title>Virtual Cards - Kazh.com | Spend Management & Company Card</title>
            </Helmet>
                <section id="banner">
                  <Header />

                    <div class="container-fluid title">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

                                <div class="text">
                                    <h1 data-aos="fade-down">Virtual Cards</h1>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 ">
                                <img width="70%" src="./images/product-invoice.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 mt-lg-0 mt-5 mt-md-5 text-lg-left text-center">

                                <h1>
                                    Pre-approved virtual debit cards</h1>
                                <p>Request single-use virtual cards for online purchasing and reduce the risks of
                                    internal and external fraud.</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Seamless online spending</h1>
                                <p data-aos="fade-down">Easily pay with a one-click copy & paste to
                                    your merchant browser
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/em_logo_Card.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 ">
                                <img data-aos="fade-left" width="70%" src="./images/product-7.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 col-12 text-lg-left text-center">

                                <h1 data-aos="fade-down">Recurring virtual cards for subscriptions</h1>
                                <p data-aos="fade-down">
                                    Manage online subscriptions and services with recurring debit cards that can be paused or updated at any
                                    time.</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Real-time spending visibility</h1>
                                <p data-aos="fade-down">Access all transactions and receipts in real
                                    time from one payment dashboard to keep track of spending.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/product-2.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>

               
                <Footer />
            </>
        );
    }
}

export default VirtualCards;

