import React, { Component } from "react";
import { BrowserRouter as Link, NavLink } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <>
        <nav class="navbar navbar-expand-lg d-lg-block d-sm-none d-md-none d-none">
          <div class="navbar">
            <i class="bx bx-menu"></i>
            <div class="logo">
              <a href="/#">
                <NavLink to="/">
                  <img src="./images/kazh_fav.png" alt=""/>
                </NavLink>
              </a>
            </div>
            <div class="nav-links">
              <div class="sidebar-logo">
                <img width="50%" src="./images/kazh_logo.png" alt="" />
                <i class="bx bx-x"></i>
              </div>
              <ul class="links">
                <li>
                  <a href="/#">
                    <NavLink to="/">Home</NavLink>
                  </a>{" "}
                </li>
                <li class="sub">
                  <a href="#">Products</a>
                  <i class="bx bxs-chevron-down htmlcss-arrow arrow"></i>
                  <ul class="htmlCss-sub-menu sub-menu">
                    {/* <li>
                      <a href="/#">
                        <NavLink to="/wallet">Wallet</NavLink>
                      </a>
                    </li> */}
                    <li>
                      {" "}
                      <a href="/#">
                        <NavLink to="/spend-control">Spend Control</NavLink>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <NavLink to="/virtual-cards">Virtual Cards</NavLink>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <NavLink to="/employee-debit-card">
                          Employee Debit Cards
                        </NavLink>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <NavLink to="/expense-reimbursements">
                          Expense Reimbursements
                        </NavLink>
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <NavLink to="/accounting">
                          Accounting Automation
                        </NavLink>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <NavLink to="/spending-report">Spending Report</NavLink>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <NavLink to="/budgets">Budgets</NavLink>{" "}
                      </a>
                    </li>
                    <li class="more d-none">
                      <span>
                        <a href="#">More</a>
                        <i class="bx bxs-chevron-right arrow more-arrow"></i>
                      </span>
                      <ul class="more-sub-menu sub-menu">
                        <li>
                          <a href="#">Neumorphism</a>
                        </li>
                        <li>
                          <a href="#">Pre-loader</a>
                        </li>
                        <li>
                          <a href="#">Glassmorphism</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li class="sub">
                  <a href="#">Resources</a>
                  <i class="bx bxs-chevron-down js-arrow arrow "></i>
                  <ul class="js-sub-menu sub-menu">
                    <li>
                      <a href="/#">
                        <NavLink to="/about">About Us</NavLink>
                      </a>
                    </li>
                    {/* <li>
                      <a href="/#">
                        <NavLink to="/detail">Details</NavLink>
                      </a>
                    </li> */}
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://medium.com/@kazh"
                      >
                        Blog{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <NavLink to="/faq">FAQs</NavLink>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://podcasts.apple.com/us/podcast/applause/id1530991276"
                      >
                        Podcast{" "}
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <a href="/#">
                    <NavLink to="/wallet">Wallet</NavLink>
                  </a>
                   <a href="/#">
                    <NavLink to="/about">About Us</NavLink>
                  </a> 
                </li> */}
                {/* <li>
                  <a href="/#">
                    <NavLink to="/wallet">Wallet</NavLink>
                  </a>
                </li> */}
                <li>
                  <a href="/#">
                    <NavLink to="/contact">Contact Us</NavLink>
                  </a>
                </li>
              </ul>
            </div>
            <div class="search-box d-lg-block d-md-none d-sm-none d-none">
              <i class="bx bx-search d-none"></i>
              <div class="input-box d-none">
                <input type="text" placeholder="Search..." />
              </div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://forms.kazh.com/index.php?r=app%2Fforms&slug=kazh-onboarding-2lyEsw"
              >
                <button>Get Started</button>
              </a>
            </div>
          </div>
        </nav>
        <div
          id="mobile_nav"
          class="d-lg-none d-md-block d-sm-block d-block fixed-top"
        >
          <div class="menu-btn" style={{ textAlign: "left" }}>
            <img className="text-left" src="./images/menu-icon.svg" alt="" />
          </div>
          <a className="mob_logo_a " href="/">
            <img
              className="mob_logo mt-4"
              src="./images/kazh_fav.png"
              alt=""
            />
          </a>

          <div class="side-bar">
            <header>
              <div class="close-btn">
                <i class="fas fa-times"></i>
              </div>
            </header>

            <div class="menu">
              <div class="item">
                <NavLink to="/">
                  <a class="nav-link" href="/#">
                    Home
                  </a>
                </NavLink>{" "}
              </div>
              <div class="item">
                <a class="sub-btn">
                  Products<i class="fas fa-angle-right dropdown"></i>
                </a>
                <div class="sub-menu">
                  <a class="dropdown-item sub-item">
                    {" "}
                    <NavLink to="/spend-control">Spend Control</NavLink>
                  </a>
                  <a class="dropdown-item sub-item">
                    <NavLink to="/virtual-cards">Virtual Cards</NavLink>
                  </a>
                  <a class="dropdown-item sub-item">
                    <NavLink to="/employee-debit-card">
                      Employee Debit Cards
                    </NavLink>
                  </a>
                  <a class="dropdown-item sub-item">
                    <NavLink to="/expense-reimbursements">
                      Expense Reimbursements
                    </NavLink>
                  </a>
                  <a class="dropdown-item sub-item">
                    <NavLink to="/accounting">Accounting Automation</NavLink>
                  </a>
                  <a class="dropdown-item sub-item">
                    <NavLink to="/spending-report">Spending Report</NavLink>
                  </a>
                  <a class="dropdown-item sub-item">
                    <NavLink to="/budgets">Budgets</NavLink>
                  </a>
                </div>
              </div>
              <div class="item">
                <a class="sub-btn">
                  Resources<i class="fas fa-angle-right dropdown"></i>
                </a>
                <div class="sub-menu">
                  <a
                    class="dropdown-item sub-item"
                    target="_blank"
                    rel="noreferrer"
                    href="https://medium.com/@kazh"
                  >
                    Blog{" "}
                  </a>
                  <a class="dropdown-item sub-item">
                    <NavLink to="/faq">FAQs</NavLink>
                  </a>
                  <a
                    class="dropdown-item sub-item"
                    target="_blank"
                    rel="noreferrer"
                    href="https://podcasts.apple.com/us/podcast/applause/id1530991276"
                  >
                    Podcast{" "}
                  </a>
                </div>
              </div>
              <div class="item">
                <a class="nav-link" href="/#">
                  <NavLink to="/about">About Us</NavLink>
                </a>
              </div>
              <div class="item">
                <a class="nav-link" href="/#">
                  <NavLink to="/contact">Contact Us</NavLink>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;
