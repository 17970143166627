import React, { Component } from "react";
import { BrowserRouter as Link, NavLink } from "react-router-dom";
import { CiLinkedin, CiFacebook, CiInstagram, CiTwitter } from "react-icons/ci";

class Footer extends Component {
  render() {
    return (
      <>
        <section id="yellow_card">
          <div class="container-fluid">
            <div class="row justify-content-center align-items-center">
              <div class="col-lg-10 left col-md-8 col-sm-12">
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-lg-left text-center">
                    <h1 class="text-lg-left text-md-center text-center">
                      Make the switch <br /> to smarter company <br />
                      spending today.
                    </h1>
                  </div>
                  <div class="col-lg-6 text-md-center text-sm-center text-center pt-lg-0 pt-md-0 pt-sm-4  pt-4">
                    <div class="row button-left">
                      <div class="col-lg-5 col-md-12  my-md-3  get_Started">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://forms.kazh.com/index.php?r=app%2Fforms&slug=kazh-onboarding-2lyEsw"
                        >
                          <button>Get Started</button>
                        </a>
                      </div>
                      <div class="col-lg-7  col-md-12 demo">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          // href="https://calendly.com/kazhcom"
                        >
                          {/* <button>Web 3 Wallet</button> */}
                          <NavLink to="/wallet"  onClick={() => window.scrollTo(0, 0)}><button> Web 3 Wallet</button></NavLink>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-4 col-md-12 col-sm-12 text-lg-left text-center">
                <NavLink to="/" onClick={() => window.scrollTo(0, 0)}>
                  <img src="./images/kazh_logo.png" alt="" />
                </NavLink>

                <h6>People Are Worth The Investment</h6>
                <p className="para">
                  Companies that trust and empower their people are able to move
                  faster, do better work and ultimately be more successful.
                  That's how we've built Kazh.
                </p>

                <ul className="social_icon justify-content-lg-start justify-content-center">
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="fb text-center"
                      href="https://www.facebook.com/KazhFintech"
                    >
                      {/* <i class="fa fa-facebook" style={{ color: "red" }}></i> */}
                      {/* CiLinkedin */}
                      <h3>
                        {" "}
                        <CiFacebook height="14" />
                      </h3>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="li"
                      href="https://www.Linkedin.com/company/kazh"
                    >
                      {/* LinkedIn */}

                      <h3>
                        <CiLinkedin />
                      </h3>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      className="in"
                      rel="noreferrer"
                      href="https://www.instagram.com/kazhfintech/"
                    >
                      {/* Instagram */}
                      <h3>
                        <CiInstagram />
                      </h3>
                    </a>
                  </li>
                  <li>
                    <a
                      className="tw"
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/KazhFintech"
                    >
                      {/* Twitter */}
                      <h3>
                        <CiTwitter />
                      </h3>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-8 col-md-12 col-sm-12 text-lg-left text-md-center text-sm-center text-center">
                <div class="row ">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="row justify-content-center">
                      <div class="col-lg-12 col-md-6 col-sm-6 foot_pad">
                        <h3>Product</h3>
                        <ul>
                          <li>
                            <a href="/#">
                              <NavLink
                                to="/spend-control"
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                Spend Control
                              </NavLink>
                            </a>
                          </li>
                          <li>
                            <a href="/#">
                              <NavLink
                                to="/virtual-cards"
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                Virtual Cards
                              </NavLink>
                            </a>
                          </li>
                          <li>
                            <a href="/#">
                              <NavLink
                                to="/employee-debit-card"
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                Employee Debit Cards
                              </NavLink>
                            </a>
                          </li>
                          <li>
                            <a href="/#">
                              <NavLink
                                to="/expense-reimbursements"
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                Expense Reimbursements
                              </NavLink>
                            </a>
                          </li>
                          <li>
                            <a href="/#">
                              <NavLink
                                to="/accounting"
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                Accounting Automation
                              </NavLink>
                            </a>
                          </li>
                          <li>
                            <a href="/#">
                              <NavLink
                                to="/spending-report"
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                {" "}
                                Spending Report
                              </NavLink>
                            </a>
                          </li>
                          <li>
                            <a href="/#">
                              <NavLink
                                to="/budgets"
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                Budgets
                              </NavLink>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-6 col-sm-6 foot_pad pl-lg-5">
                        <h3>Discover</h3>
                        <ul>
                          <li>
                            <a href="/#">
                              <NavLink
                                to="/about"
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                About Us
                              </NavLink>
                            </a>
                          </li>
                          <li>
                            <a href="/#">
                              <NavLink
                                to="/careers"
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                Careers
                              </NavLink>
                            </a>
                            {/* <a href="/careers">Careers</a> */}
                          </li>
                          <li>
                            <a href="/#">
                              <NavLink
                                to="/faq"
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                FAQs
                              </NavLink>
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://medium.com/@kazh"
                            >
                              Blog
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://podcasts.apple.com/us/podcast/applause/id1530991276"
                            >
                              Podcast
                            </a>
                          </li>
                          <li>
                            <a href="/#">
                              <NavLink
                                to="/contact"
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                Contact Us
                              </NavLink>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-lg-3 col-md-3 col-sm-6 col-12" >
                    <div class="row">
                      <div class="col-lg-12 col-md-6 col-sm-6 foot_pad pl-lg-5">
                        <h3>Resources</h3>
                        <ul>
                          <li>
                            <a href="/#">
                              <NavLink
                                to="/faq"
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                FAQs
                              </NavLink>
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://medium.com/@kazh"
                            >
                              Blog
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://podcasts.apple.com/us/podcast/applause/id1530991276"
                            >
                              Podcast
                            </a>
                          </li>
                        </ul>
                      </div>
                     
                    </div>
                  </div> */}
                  <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-12 address pl-4 ">
                        <h3>Our Locations</h3>
                        <h4>India</h4>
                        <p>
                          Kazh Fintech Private Limited <br /> Plot No.1/1A, UR
                          Nagar Extn., <br className="d-none d-lg-block" /> Anna Nagar West Extn, Padi,
                          Chennai, Tamilnadu - 600050, India
                        </p>
                        {/* <p> PLOT NO.1/1A, UR NAGAR EXTN., ANNA NAGAR WEST EXTN, PADI CHENNAI, TAMIL NADU - 600050,  INDIA</p> */}
                        {/* <p>PADI CHENNAI, TAMIL NADU - 600050,  INDIA</p> */}

                        <hr className="d-none d-lg-block " />
                      </div>

                      <div class="col-lg-12 col-md-12 col-sm-12 col-12 address pl-4">
                        <h4>United Kingdom</h4>
                        <p>
                          Kazh Fintech Ltd <br />
                          71-75 Shelton Street, Covent Garden, <br className="d-none-lg d-block" /> London, WC2H 9JQ.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <hr />
              </div>
            </div>
          </div>
          <div class="container-fluid bt_foot">
            <div class="row">
              <div class="col-lg-6 col-md-6 text-lg-left text-md-left text-center pt-lg-0 pt-md-4">
                <p>© 2022 Kazh Fintech Private Limited • All rights reserved</p>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-lg-right text-md-right text-center ">
                <a href="https://splendio.com/" target="_blank">
                  Made with <i class="fa fa-heart" style={{ color: "red" }}></i>{" "}
                  by Splendor
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Footer;
