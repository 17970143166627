import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { Helmet } from 'react-helmet';



class About extends Component {
    

    render() {
            

        return (
            
            <>
            <Helmet>
                <title>About - Kazh.com | Spend Management & Company Card</title>
            </Helmet>
                <section id="banner">
                    <Header />

                    <div class="container-fluid title">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

                                <div class="text">
                                    <h1>About Us.</h1>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text" style={{ backgroundColor: '#ffffff' }}>
                    <div class="container-fluid">
                        <div class="row justify-content-center align-items-center text-center">


                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-lg-block d-none">

                                <img width="100%" src="./images/about_1.png" alt=""  style={{ width:'920px' }}/>

                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-lg-none d-block">

                                <img width="100%" src="./images/about_1.png" alt=""  style={{ width:'100%', height:'100%'}}/>

                            </div>
                        </div>
                    </div>
                </section>

                <section id="about">
                    <div class="container-fluid">
                        <div class="row justify-content-center align-items-center mt-lg-5">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-2 pt-lg-0 pt-5">

                                <h1 data-aos="fade-down">We’re Building The Future of Corporate Payments</h1>
                                <p data-aos="fade-down">Our mission is to help businesses manage their corporate payments with
                                    complete transparency, control and insights ensuring their finances always run smoothly while
                                    maximizing their resources.</p>
                                <p data-aos="fade-down">We believe the corporate card was never built to shoulder an entire
                                    company’s spend and SaaS payments. With cardless payments, Kazh provides businesses with a
                                    one-stop hub to orchestrate, manage, analyze and optimize, reconcile, and reduce their corporate
                                    spend and subscription payments while preventing subscription payment failures that can result
                                    in business disruption.</p>
                            </div>
                            <div class="col-lg-6 right text-lg-left text-center col-md-12 col-sm-12 col-12 d-lg-block d-none order-lg-2 order-1">
                                <img data-aos="fade-left" width="100%" height="100%" src="./images/about_2.png" alt="" />
                            </div>
                            <div class="col-lg-6 right text-lg-left text-center col-md-12 col-sm-12 col-12 d-lg-none d-block order-lg-2 order-1">
                                <img data-aos="fade-left" width="50%" src="./images/about_2.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                <section id="about_meet">
                    <div class="container-fluid">
                        <div class="row justify-content-center align-items-center mt-lg-5">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center">

                                <h1 data-aos="fade-down">Meet the<br/>Team</h1>
                            </div>
                            <div class="col-lg-6 right text-lg-left text-center col-md-12 col-sm-12 ">
                                <p class="mb-5" data-aos="fade-down">Kazh Fintech Private Limited was founded by globally recognized payments and tech leaders looking
                                    to re-write the future of payments today. We are a supportive group of innovators, disruptors,
                                    and fintech geeks on a mission to make your payments smarter, smoother, and more secure. We
                                    believe in building a transparent and dynamic world, and we highly invest in our customers,
                                    product and people.</p>

                                <a href="/careers"><button data-aos="fade-right">Join Our Team <i class='fas fa-arrow-right' style={{ padding: '0 5px 0 10px',fontSize: '15px'}}></i></button></a>
                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text" style={{ backgroundColor: '#ffffff' }}>
                    <div class="container-fluid">
                        <div class="row justify-content-center align-items-center text-center">


                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-lg-block d-none">

                                <img width="100%" src="./images/about_3.jpg" alt="" style={{ width:'1000px', height:'670px' }}/>

                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-lg-none d-block">

                                 <img width="100%" src="./images/about_3.jpg" alt="" style={{ width:'100%', height:'100%' }}/>

                            </div>
                        </div>
                    </div>
                </section>
              
                <Footer />
            </>
        );
    }
}

export default About;
