import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from "react-helmet";
import "../";

let accordionButtons = document.getElementsByClassName(
  "accordion-item__button"
);

for (let i = 0; i < accordionButtons.length; i++) {
  accordionButtons[i].addEventListener("click", function () {
    this.classList.toggle("active");
    let accordionContent = this.nextElementSibling;

    if (accordionContent.style.maxHeight) {
      accordionContent.style.maxHeight = null;
    } else {
      accordionContent.style.maxHeight = accordionContent.scrollHeight + "px";
    }
  });
}

class FAQ extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>FAQ - Kazh.com | Spend Management & Company Card</title>
        </Helmet>
        <section id="banner">
          <Header />

          <div class="container-fluid title">
            <div class="row justify-content-center">
              <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">
                <div class="text">
                  <h1 data-aos="fade-down">FAQs.</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="faq">
          <div class="container" style={{ margin: "100px auto" }}>
            <div class="row justify-content-center">
              <div class="col-md-12">
                

                <div class="accordion" id="accordionExample">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h2 class="mb-0">
                        <button
                          id="accordion-item__button"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What is kazh.com?
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      class="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p style={{ paddingTop: "40px" }}>
                          <a
                            style={{ color: "#c36", textDecoration: "none" }}
                            href=""
                          >
                            kazh.com
                          </a>
                          (Cash) is the first spend management platform built
                          for both finance teams and employees. It gives finance
                          leaders visibility across all company spending.
                        </p>
                        <p>
                          Employees can spend what they need, and finance has
                          full control of all spending in real time
                        </p>
                        <p style={{ paddingBottom: "40px" }}>
                          Secure, custom payment methods ensure that spending
                          stays on-policy, and approval workflows give managers
                          the right to refuse. kazh.com enables them to benefit
                          from streamlined expense and invoice management
                          through the website and app, whether on the road or in
                          the office.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h2 class="mb-0">
                        <button
                          type="button"
                          id="accordion-item__button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How long does it take to get started?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p
                          style={{ paddingTop: "40px", paddingBottom: "40px" }}
                        >
                          It takes only a few minutes to set up an account and
                          start issuing virtual cards. There is no waiting
                          period.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h2 class="mb-0">
                        <button
                          type="button"
                          id="accordion-item__button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Can anyone open a Kazh account?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p
                          style={{ paddingTop: "40px", paddingBottom: "40px" }}
                        >
                          Kazh is currently only available to India. based
                          businesses and merchants.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFour">
                      <h2 class="mb-0">
                        <button
                          type="button"
                          id="accordion-item__button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Is Kazh secure?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFour"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p
                          style={{ paddingTop: "40px", paddingBottom: "40px" }}
                        >
                          Kazh uses 256-bit Secure Socket Layer (SSL) encryption
                          to protect your personal data. Your information is
                          stored in highly-secure data centers that use
                          state-of-the art electronic surveillance and
                          multi-factor access control systems.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFive">
                      <h2 class="mb-0">
                        <button
                          type="button"
                          id="accordion-item__button"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          How do Virtual cards work?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFive"
                      class="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p
                          style={{ paddingTop: "40px", paddingBottom: "40px" }}
                        >
                          Employees who need to spend online can access
                          single-use virtual cards for one-off purchases, or
                          virtual recurring cards for subscription management
                          and digital ads.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingSix">
                      <h2 class="mb-0">
                        <button
                          type="button"
                          id="accordion-item__button"
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          Why is virtual better?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseSix"
                      class="collapse"
                      aria-labelledby="headingSix"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p style={{ paddingTop: "40px" }}>
                          Each virtual card comes with its own unique card
                          details. This is more secure than sharing the company
                          credit card on hundreds of websites. And cards can be
                          frozen or deleted at any time from within the
                          platform.
                        </p>
                        <p style={{ paddingBottom: "40px" }}>
                          Users can manage how much money is on a card at any
                          given time, and can always see who is spending what.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingSeven">
                      <h2 class="mb-0">
                        <button
                          type="button"
                          id="accordion-item__button"
                          data-toggle="collapse"
                          data-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          Does kazh provide physical card?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseSeven"
                      class="collapse"
                      aria-labelledby="headingSeven"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <p style={{ paddingTop: "40px" }}>
                          Yes, employees can use debit Mastercards for on-the-go
                          business travel expenses and in-store purchasing.
                        </p>
                        <p>
                          Finance teams and appointed managers can control cards
                          in a simple dashboard to:
                        </p>
                        <ul
                          style={{
                            marginLeft: "100px",
                            paddingBottom: "40px",
                            color: "#262e33",
                          }}
                        >
                          <li>Set budgets for each cardholder;</li>
                          <li>Enable cardholders to withdraw cash or not;</li>
                          <li>Add more funds;</li>
                          <li>Reduce budgets or block cards at any time.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section id="faq">
                    <div class="container" style={{ margin: '100px auto', }}>
                        <div class="row justify-content-center">
                            <div class="col-md-12">

                               

                                <div class="accordion">
                                    <div  class="accordion-item">
                                        <button class="accordion-item__button">What is kazh.com?</button>
                                        <div class="accordion-item__content">
                                            <p style={{ paddingTop: '40px',}} ><a style={{ color: '#c36', textDecoration: 'none' }} href="">kazh.com</a>(Cash)
                                                is the
                                                first spend management platform built for both finance teams and employees. It
                                                gives finance leaders visibility across all company spending.</p>
                                            <p>Employees can spend what they need, and finance has full control of all spending in real time</p>
                                            <p style={{ paddingBottom: '40px',}}>Secure, custom payment methods ensure that spending stays on-policy,
                                                and approval workflows give managers the right to refuse. kazh.com enables them to benefit from
                                                streamlined expense and invoice management through the website and app, whether on the road or in the
                                                office.</p>
                                        </div>
                                    </div>
                                    <div  class="accordion-item">
                                        <button class="accordion-item__button">How long does it take to get started?</button>
                                        <div class="accordion-item__content">
                                            <p style={{ paddingTop: '40px', paddingBottom: '40px', }}>It takes only a few minutes to set up an account and
                                                start issuing virtual cards. There is no waiting period.</p>
                                        </div>
                                    </div>
                                    <div  class="accordion-item">
                                        <button class="accordion-item__button">Can anyone open a Kazh account?</button>
                                        <div class="accordion-item__content">
                                            <p style={{ paddingTop: '40px', paddingBottom: '40px', }}>Kazh is currently only available to India. based
                                                businesses and merchants.</p>
                                        </div>
                                    </div>
                                    <div  class="accordion-item">
                                        <button class="accordion-item__button">Is Kazh secure?</button>
                                        <div class="accordion-item__content">
                                            <p style={{ paddingTop: '40px', paddingBottom: '40px', }}>Kazh uses 256-bit Secure Socket Layer (SSL)
                                                encryption to protect your personal data. Your information is stored in highly-secure data centers
                                                that use state-of-the art electronic surveillance and multi-factor access control systems.</p>
                                        </div>
                                    </div>
                                    <div  class="accordion-item">
                                        <button class="accordion-item__button">How do Virtual cards work?</button>
                                        <div class="accordion-item__content">
                                            <p style={{ paddingTop: '40px', paddingBottom: '40px', }}>Employees who need to spend online can access
                                                single-use virtual cards for one-off purchases, or virtual recurring cards for subscription management
                                                and digital ads.</p>
                                        </div>
                                    </div>
                                    <div  class="accordion-item">
                                        <button class="accordion-item__button">Why is virtual better?</button>
                                        <div class="accordion-item__content">
                                            <p style={{ paddingTop: '40px',}}>Each virtual card comes with its own unique card details. This is more
                                                secure than sharing the company credit card on hundreds of websites. And cards can be frozen or
                                                deleted at any time from within the platform.</p>
                                            <p style={{ paddingBottom: '40px',}}>Users can manage how much money is on a card at any given time, and can
                                                always see who is spending what.</p>
                                        </div>
                                    </div>

                                    <div  class="accordion-item">
                                        <button class="accordion-item__button">Does kazh provide physical card?</button>
                                        <div class="accordion-item__content">
                                            <p style={{ paddingTop: '40px',}}>Yes, employees can use debit Mastercards for on-the-go business travel
                                                expenses and in-store purchasing.</p>
                                            <p>Finance teams and appointed managers can control cards in a simple dashboard to:</p>
                                            <ul style={{ marginLeft:'100px',paddingBottom: '40px', color: '#262e33' }}>
                                                <li>Set budgets for each cardholder;</li>
                                                <li>Enable cardholders to withdraw cash or not;</li>
                                                <li>Add more funds;</li>
                                                <li>Reduce budgets or block cards at any time.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section> */}

        <Footer />
      </>
    );
  }
}

export default FAQ;
