import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { Helmet } from "react-helmet";

document.title = "Home - Kazh.com | Spend Management & Company Card";
class Home extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Home - Kazh.com | Spend Management & Company Card</title>
        </Helmet>
        <section id="banner">
          <Header />

          <div class="container-fluid">
            <div class="row justify-content-lg-center">
              <div class="col-lg-6 ban_left col-md-6 col-sm-12 col-12 pt-lg-0 pt-5 text-lg-left text-md-left text-center">
                <h2 class="pt-lg-0 pt-5">The all-in-one</h2>
                <h1>spending solution</h1>
                {/* <h1>Financial stack for web3 teams</h1> */}
                {/* <h2 class="pt-lg-0 pt-5">for web3 teams</h2> */}
                {/* <h2 class="pt-lg-0 pt-5" style={{ color:'white' }}>for Web 2 & Web 3</h2> */}

                <p>
                  Take control of all spending with company cards, expense
                  reimbursements, invoice management and automated accounting —
                  all in one place.
                </p>

                {/* <p>Corporate cards and expense management for web3 projects. And much, much more...</p> */}

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-12 get_Started">
                    <a
                      target="_blank"
                      rel="noopener"
                      href="https://forms.kazh.com/index.php?r=app%2Fforms&slug=kazh-onboarding-2lyEsw"
                    >
                      <button>Get Started</button>
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-12 demo">
                    <a
                      target="_blank"
                      rel="noopener"
                      // href="https://calendly.com/kazhcom"
                      href="/#"
                    >
                      <a
                        target="_blank"
                        rel="noopener"
                        // href="https://calendly.com/kazhcom"
                        href="/#"
                      >
                        {/* <button>Book a Demo</button> */}
                        <NavLink to="/wallet"><button> Web 3 Wallet</button></NavLink>
                      </a>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 ban_right col-md-6 col-sm-12 col-12 text-lg-left text-md-center mt-lg-0 mt-md-5 text-center mt-5 pt-lg-0 pt-md-5">
                <img width="100%" src="./images/kazh_bannernew.png" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section id="build">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                <h1 data-aos="fade-down" class="text-lg-left text-center">Build a healthy spend culture</h1>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 mt-lg-0  mt-md-5 col-12 mt-5">
                <p class="text-lg-left text-md-center text-center m-auto">
                  Usually finance teams have to fight to convince employees to
                  adopt new tools and processes. Not with kazh.com. With clear
                  benefits for finance, managers and employees, get the right
                  balance between control and efficiency when spending company
                  money.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="text_image">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-md-2 order-sm-2 order-2 order-2">
                <h6 data-aos="fade-down" class="pt-lg-0 pt-3">Approvals</h6>
                <h1 data-aos="fade-down" class="pt-lg-0 pt-3">
                  Get control & visibility over every payment
                </h1>
                <p>
                  Establish a request and approval process to increase spend
                  accountability without blocking employees
                </p>
                <a class="learn" href="">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/spend-control"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Learn More
                  </NavLink>
                </a>
              </div>
              <div class="col-lg-6 right text-center col-md-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-md-1 order-sm-1 order-1">
                <img
                  data-aos="fade-right"
                  src="./images/approval.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section id="image_text">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 right text-lg-left text-center col-md-12 col-sm-12  mt-lg-0">
                <img
                  data-aos="fade-left"
                  width="100%"
                  src="./images/phone-cash.png"
                  alt=""
                />
              </div>
              <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center mt-lg-0 mt-5 mt-md-5">
                <h6 data-aos="fade-down">Expense cards</h6>
                <h1 data-aos="fade-down" class="pt-lg-0 pt-md-3">
                  Issue virtual & physical cards with built-in rules.
                </h1>
                <p>
                  Enable faster purchasing by giving every employee access to
                  the funds they need in a safe and secure manner.
                </p>
                <a class="learn" href="">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/virtual-cards"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Learn More
                  </NavLink>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="text_image">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-md-2 order-sm-2 order-2">
                <h6 data-aos="fade-down" class="pt-lg-0 pt-3">Bill payments</h6>
                <h1 data-aos="fade-down" class="pt-lg-0 pt-3">Manage and pay invoices easily</h1>
                <p>
                  Save time and avoid late fees on every supplier invoice by
                  streamlining your AP process from purchase order to bank
                  transfer.
                </p>
                <a class="learn" href="">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/expense-reimbursements"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Learn More
                  </NavLink>
                </a>
              </div>
              <div class="col-lg-6 right  text-center col-md-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-md-1 order-sm-1 order-1">
                <img
                  width="70%"
                  data-aos="fade-right"
                  src="./images/invoice.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section id="image_text">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 right text-lg-left text-center col-md-12 col-sm-12 ">
                <img
                  width="70%"
                  data-aos="fade-left"
                  src="./images/report.png"
                  alt=""
                />
              </div>
              <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center mt-lg-0 mt-5 mt-md-5">
                <h6 data-aos="fade-down">reports</h6>
                <h1 data-aos="fade-down" class="pt-lg-0 pt-3">
                  Monitor & optimise budgets in real time
                </h1>
                <p>
                  Empower finance and budget keepers to make timely and informed
                  spending decisions with real-time reports of what's actually
                  been spent.
                </p>
                <a class="learn" href="">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/budgets"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Learn More
                  </NavLink>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section
          id="text_image"
          style={{ backgroundColor: "rgb(248,249,255)" }}
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-md-2 order-sm-2 order-2">
                <h6 data-aos="fade-down" class="pt-lg-0 pt-3">Reconciliation</h6>
                <h1 data-aos="fade-down" class="pt-lg-0 pt-3">
                  Automate admin & integrate your accounting
                </h1>
                <p>
                  Eliminate double entry and save hours with custom exports to
                  your favorite accounting systems.
                </p>
                <a class="learn" href="">
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to="/accounting"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Learn More
                  </NavLink>
                </a>
              </div>
              <div class="col-lg-6 right text-center col-md-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-md-1 order-sm-1 order-1">
                <img
                  data-aos="fade-right"
                  width="70%"
                  src="./images/recoin-2.a0cc0c2.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section id="featured">
          <div class="container-fluid">
            <div class="row justify-content-center text-center">
              <div class="col-lg-12 left col-md-12 col-sm-12">
                <div class="text" data-aos="fade-down">
                  <h1>Featured on</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="daily">
          <div class="container-fluid">
            <div class="row justify-content-center text-center">
              <div class="col-lg-12 left col-md-12 col-sm-12 col-12">
              <a href="https://df.media/these-are-the-top-fintech-financial-technology-companies-in-delaware-2021/" target="_blank"><img width="30%" src="./images/Daily-finance.png" alt="" /></a>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

export default Home;
