import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from 'react-helmet';


class Accounting extends Component {

    render() {

        return (
            <>
            <Helmet>
                <title>Accounting - Kazh.com | Spend Management & Company Card</title>
            </Helmet>
                <section id="banner">
                    <Header />

                    <div class="container-fluid title">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

                                <div class="text" style={{ width: '80%' }}>
                                    <h1 data-aos="fade-down">Accounting Automation</h1>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img width="70%" src="./images/product-invoice.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 col-12 text-lg-left text-center">

                                <h1>


                                    Automatically reconcile transactions and receipts</h1>
                                <p>Access transactions and receipts stored in a unique place, and automatically remind
                                    employees if needed.</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-5 pt-5">Automate expense account
                                    allocation</h1>
                                <p data-aos="fade-down">Create rules to map your expense
                                    accounts with Kazh payment categories, and save time preparing your exports.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12mt-lg-0 mt-4 mt-md-4 order-lg-1 order-2">
                                <img data-aos="fade-right" width="70%" src="./images/product-2.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12 ">
                                <img data-aos="fade-left" width="70%" src="./images/product-6.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 text-lg-left text-center">

                                <h1 data-aos="fade-down" >
                                    Prepare and export payments in 2 steps</h1>
                                <p data-aos="fade-down">
                                    Use the Expense Inbox interface to prepare and sort transactions, verify information, and export to your
                                    accounting software.

                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Export Kazh data to your
                                    accounting automation software</h1>
                                <p data-aos="fade-down" >Use our native integrations and our custom
                                    exports to effortlessly integrate Kazh transactions and receipts into your existing system.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-1 order-2">
                                <img data-aos="fade-right" width="70%" src="./images/productpng.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>



                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img data-aos="fade-left" width="70%" src="./images/virtual_card.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 text-lg-left text-center">

                                <h1 data-aos="fade-down">Consolidate and track all payments</h1>
                                <p data-aos="fade-down">
                                    Manage employee expense reimbursements along with other transactions for better cost centre tracking and
                                    budget follow-up. Receipts are digitised and stored safely.

                                </p>

                            </div>
                        </div>
                    </div>
                </section>







               
              
                <Footer />
            </>
        );
    }
}

export default Accounting;
