import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Accounting from "./components/Accounting";
import Budgets from "./components/Budgets";
import Careers from "./components/Careers";
import Contact from "./components/Contact";
import EmployeeDebitCard from "./components/EmployeeDebitCard";
import ExpenseReimbursements from "./components/ExpenseReimbursements";
import FAQ from "./components/FAQ";
import SpendControl from "./components/SpendControl";
import SpendingReport from "./components/SpendingReport";
import VirtualCards from "./components/VirtualCards";
import Wallets from "./components/wallet";
import Siri from "./components/Siri";






class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundUrl: "",
    };
  }
  async componentDidMount() {}
  state = {};
  render() {
    return (
      <Router >
        <Routes>
            <Route path="/" element={<Home />}  />
            <Route path="/about" element={<About />} />
            <Route path="/accounting" element={<Accounting />} />
            <Route path="/budgets" element={<Budgets />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/employee-debit-card" element={<EmployeeDebitCard />} />
            <Route path="/expense-reimbursements" element={<ExpenseReimbursements />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/spend-control" element={<SpendControl />} />
            <Route path="/spending-report" element={<SpendingReport />} />
            <Route path="/virtual-cards" element={<VirtualCards />} />
            <Route path="/wallet" element={<Wallets />} />
            {/* <Route path="/detail" element={<Siri />} /> */}

        </Routes>
      </Router>
    );
  }
}

export default App;
