import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from 'react-helmet';



class SpendingReport extends Component {

    render() {
        return (
            <>
            <Helmet>
                <title>Spending Report - Kazh.com | Spend Management & Company Card</title>
            </Helmet>
                <section id="banner">
                   <Header />

                    <div class="container-fluid title">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

                                <div class="text" style={{width: '60%',}}>
                                    <h1 data-aos="fade-down">Spending Report</h1>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img width="70%" src="./images/product-2.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 text-lg-left text-center">

                                <h1>


                                    View consolidated spending reports in detail</h1>
                                <p>Payments are gathered in one place, in real time, with vital information and cost
                                    categories.</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Never pay for the same service
                                    twice</h1>
                                <p data-aos="fade-down">Subscriptions and recurring
                                    services have dedicated cards, are grouped together in the platform, and can be paused in one click.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/product-6.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 ">
                                <img data-aos="fade-left" width="70%" src="./images/product-5.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 text-lg-left text-center">

                                <h1 data-aos="fade-down" >

                                    Use labels to track cost centers and projects</h1>
                                <p data-aos="fade-down">
                                    Easily tag transactions with cost centers and projects labels, and get your reports in a click.

                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                
                <Footer />
            </>
        );
    }
}

export default SpendingReport;
