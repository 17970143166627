import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from 'react-helmet';



class EmployeeDebitCard extends Component {

    render() {
        return (
            <>
            <Helmet>
                <title>Employee Debit Card - Kazh.com | Spend Management & Company Card</title>
            </Helmet>
                <section id="banner">
                    <Header />

                    <div class="container-fluid title">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

                                <div class="text" style={{width: '70%',}}>
                                    <h1 data-aos="fade-down">Employee Debit Cards</h1>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img width="70%" src="./images/product-invoice.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 col-12 text-lg-left text-center">

                                <h1>

                                    Define individual budgets on debit cards</h1>
                                <p>Grant employees a personal budget on their physical card to perform field purchases.</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Customise settings and create rules</h1>
                                <p data-aos="fade-down">Set specific time slots, days of the week, and expense categories as allowed per employee. Activate and deactivate the cash withdrawal option in a single click.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/em_logo_Card.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img data-aos="fade-left" width="70%" src="./images/product-2.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 col-12 text-lg-left text-center">

                                <h1 data-aos="fade-down">Connect via the mobile application</h1>
                                <p data-aos="fade-down">
                                    Employees can easily see their debit card balance, top-up, get their PIN and be notified post-payment to add purchase information and receipts.</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Track all payments in real time</h1>
                                <p data-aos="fade-down">Instantly see consolidated online spending in the payments interface. Stay up to date on budgets and detect unusual transactions.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/product-1.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
               
                <Footer />
            </>
        );
    }
}

export default EmployeeDebitCard;
