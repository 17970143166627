import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from 'react-helmet';



class SpendControl extends Component {

    render() {
        return (
            <>
            <Helmet>
                <title>Spend Control - Kazh.com | Spend Management & Company Card</title>
            </Helmet>
                <section id="banner">
                    <Header />

                    <div class="container-fluid title">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

                                <div class="text">
                                    <h1 data-aos="fade-down">Spend Control</h1>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right col-md-12 col-sm-12 col-12 text-lg-center text-center">
                                <img width="70%" src="./images/productpng.png" alt=""/>
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 mt-lg-0 mt-5 mt-md-5 text-lg-left text-center">

                                <h1>Individual spending controls and approval thresholds</h1>
                                <p>Set up individual spending limits and team approval workflows to put budget owners in
                                    control of every
                                    transaction.</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-md-2 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Complete transaction history</h1>
                                <p data-aos="fade-down">See the company's purchase history with a full
                                    audit trail of
                                    requests, approvals, reviews, and exports.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-md-1 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/product-2.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
              <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img data-aos="fade-left" width="70%" src="./images/virtual_card.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 col-12 text-lg-left text-center">

                                <h1 data-aos="fade-down">Single-use virtual cards combined with control rules</h1>
                                <p data-aos="fade-down">
                                    Single-use virtual cards combined with control rules
                                    Generate single-use, capped virtual cards for one-off purchases. These prevent internal and external fraud
                                    risk.</p>

                            </div>
                        </div>
                    </div>
                </section> 
                 <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-md-2 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Real-time spend overview</h1>
                                <p data-aos="fade-down">View consolidated data for all team
                                    transaction, in real-time, with
                                    all important payment information. Control cash flow and anticipate upcoming supplier payments.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-md-1 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/product-invoice.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                <section id="full_image_text">
                    <div class="container-fluid">
                        <div class="row align-items-center">

                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12">
                                <img data-aos="fade-left" width="70%" src="./images/product-5.png" alt="" />
                            </div>
                            <div class="col-lg-6 left col-md-12 col-sm-12 mt-lg-0 mt-5 mt-md-5 col-12 text-lg-left text-center">

                                <h1 data-aos="fade-down">Control on physical cards</h1>
                                <p data-aos="fade-down">
                                    Limit Kazh cards to be used on specific days, during certain time slots, and for precise types of spending.
                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="full_text_image">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 left col-md-12 col-sm-12 col-12 text-lg-left text-center order-lg-1 order-md-2 order-2">

                                <h1 data-aos="fade-down" class="pt-lg-0 pt-5">Effortlessly improve
                                    spending habits</h1>
                                <p data-aos="fade-down">Improve spending habits by setting
                                    up additional post-payment rules to block users with too many unjustified payments.
                                </p>
                            </div>
                            <div class="col-lg-6 right text-lg-center text-center col-md-12 col-sm-12 col-12 mt-lg-0 mt-4 mt-md-4 order-lg-2 order-md-1 order-1">
                                <img data-aos="fade-right" width="70%" src="./images/product-6.png" alt="" />
                            </div>
                        </div>
                    </div>

                </section> 

                
                <Footer />
            </>
        );
    }
}

export default SpendControl;
