import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Helmet } from 'react-helmet';

class Careers extends Component {
    render() {
        return (
            <>
             <Helmet>
                <title>Careers - Kazh.com | Spend Management & Company Card</title>
            </Helmet>
                <section id="banner">
                    <Header />

                    <div class="container-fluid title">
                        <div class="row justify-content-center">
                            <div class="col-lg-12 left col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

                                <div class="text">
                                    <h1>Careers</h1>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>

                <section id="career">
              <div className="container-fluid">
                <div className="row justify-content-center">
                <div class="col-lg-10 col-md-12 col-sm-12 col-12 pt-lg-0 pt-5">

<iframe style={{ width: '100%', height: '70vh', overflow: 'hidden', border:'0'}} src="https://kazh.com/career_iframe.html" scrolling="no" />

</div>
                </div>
              </div>
                   
                    
                </section>
              

                
                <Footer />
            </>
        );
    }
}

export default Careers;
